import { createContext, useState, useEffect } from 'react';
import { router } from "@inertiajs/react";
import { toast } from "react-toastify";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [favorites, setFavorites] = useState([]);

    useEffect(() => {
        const storedFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
        setFavorites(storedFavorites);
    }, []);


    useEffect(() => {
        localStorage.setItem('favorites', JSON.stringify(favorites));
    }, [favorites]);

    const removeFromCart = (product) => {
        router.post(`/cart/remove`, {
            "product_id": product.id,
        }, { preserveScroll: true });
        toast.success(`Removed ${product.title} from your cart`);
    };

    const addToCart = (product , quantity = 1 , show_notification = true) => {
        router.post(`/cart/add`, {
            "product_id": product.id,
            "quantity": quantity
        }, { preserveScroll: true });
        if (show_notification) {
            toast.success(`Added ${product.title} to your cart`);
        }
    };


    const isProductInCart = (productId) => {
        // return cart.product_ids.includes(productId);
    };

    const addToFavorites = (product) => {
        const productExists = favorites.some(item => item.id === product.id);
        if (!productExists) {
            setFavorites(prevFavorites => [...prevFavorites, product]);
        }
    };

    const removeFromFavorites = (product) => {
        setFavorites(prevFavorites => prevFavorites.filter(item => item.id !== product.id));
    };

    const isProductInFavorites = (product) => {
        return favorites.some(item => item.id === product.id);
    };



    return (
        <CartContext.Provider value={{
            addToCart,
            removeFromCart,
            addToFavorites,
            removeFromFavorites,
            isProductInFavorites
        }}>
            {children}
        </CartContext.Provider>
    );
};
