const useScrollLock = () => {
    const lockScroll = () => {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = `${window.innerWidth - document.body.offsetWidth}px`;
    };

    const unlockScroll = () => {
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';
    };

    return { lockScroll, unlockScroll };
};

export default useScrollLock;
