import '../css/app.css'

import React from 'react';
import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { CartProvider } from './Context/CartContext';
import { AppProvider } from './Context/AppContext';
import RecaptchaProvider from './Components/RecaptchaProvider';

createInertiaApp({
    resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
    setup({ el, App, props }) {
        createRoot(el).render(

            <RecaptchaProvider>
                <AppProvider>
                    <CartProvider>
                        <App {...props} />
                    </CartProvider>
                </AppProvider>
            </RecaptchaProvider>

        );
    },
});
