import { createContext, useState, useEffect } from 'react';
import SearchModal from '../Components/SearchModal';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    return (
        <AppContext.Provider value={{
            isModalOpen,
            setIsModalOpen,
        }}>
            {children}
            <SearchModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
        </AppContext.Provider>
    );
};
