import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

export default function RecaptchaProvider({ children }) {
    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={import.meta.env.VITE_RECAPTCHA_KEY}
            scriptProps={{
                async: true,
                defer: false,
                appendTo: 'head'
            }}
        >
            {children}
        </GoogleReCaptchaProvider>
    );
}
