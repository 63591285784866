import React, { useEffect, useState } from "react";
import { TfiClose } from "react-icons/tfi";
import { CiSearch } from "react-icons/ci";
import { IoDocumentOutline } from "react-icons/io5";
import { motion, AnimatePresence } from 'framer-motion';
import useScrollLock from "../hooks/usesScrollLock";

const SearchModal = ({ isOpen, onClose }) => {
    const [input, setInput] = useState("");
    const [results, setResults] = useState([]);
    const [allData, setAllData] = useState(null);
    const [state, setState] = useState("idle");
    const { lockScroll, unlockScroll } = useScrollLock();


    useEffect(() => {
        if (isOpen) {
            lockScroll();
        } else {
            unlockScroll();
        }

        // Cleanup on unmount
        return () => unlockScroll();
    }, [isOpen]);

    // Fetch all data on component mount
    useEffect(() => {
        if (!isOpen) return;

        setState("loading");
        fetch('/search')
            .then((res) => res.json())
            .then((data) => {
                setAllData(data);
                setState("loaded");
            })
            .catch(() => setState("error"));

    }, [isOpen]);

    // Perform local regex search on the loaded data
    const handleSearch = (value) => {
        if (!allData || value.trim().length === 0) {
            setResults([]);
            return;
        }

        const regex = new RegExp(value, "i");
        const filteredResults = {};

        Object.keys(allData).forEach((group) => {
            const matchedItems = allData[group].filter((item) =>
                regex.test(item.name) || regex.test(item.content)
            );

            if (matchedItems.length > 0) {
                filteredResults[group] = matchedItems;
            }
        });

        setResults(filteredResults);
    };

    // Update results on input change
    useEffect(() => {
        handleSearch(input);
    }, [input, allData]);

    // if (!isOpen) return null;

    const modalVariants = {
        hidden: {
            opacity: 0,
            scale: 0.95,
        },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 0.2,
                ease: "easeInOut",
            },
        },
        exit: {
            opacity: 0,
            scale: 0.95,
            transition: {
                duration: 0.2,
                ease: "easeInOut",
            },
        },
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    id="search-modal-overlay"
                    className="fixed inset-0 flex items-center justify-center bg-black/60 backdrop-blur-sm z-50"
                    onClick={(e) => e.target.id === "search-modal-overlay" && onClose()}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    variants={modalVariants}
                >
                    <div className="rounded-xl shadow-lg w-full max-w-md mx-4 md:mx-auto p-5 bg-white">
                        {/* Search Input */}
                        <div className="relative flex items-center border-b border-border border-spacing-2 pb-3">
                            <CiSearch
                                className="absolute text-muted-foreground"
                                size={28}
                            />
                            <input
                                type="text"
                                onChange={(e) => setInput(e.target.value)}
                                value={input}
                                autoFocus
                                className="w-full bg-gray-100 rounded-lg pl-10 pr-10 py-3 text-sm focus:outline-none"
                            />
                            <button
                                onClick={onClose}
                                className="absolute right-4 text-muted-foreground"
                            >
                                <TfiClose size={22} />
                            </button>
                        </div>

                        {/* Results */}
                        <div className="overflow-y-auto max-h-80">
                            {state === "loading" && <div className="text-muted-foreground text-sm pt-4">Loading data...</div>}
                            {state === "error" && <div className="text-muted-foreground text-sm pt-4">Error loading data.</div>}
                            {state === "loaded" && input.trim().length === 0 && (
                                <div className="text-muted-foreground text-sm pt-4">Start typing to search...</div>
                            )}
                            {state === "loaded" && input.trim().length > 0 && Object.keys(results).length === 0 && (
                                <div className="text-muted-foreground text-sm pt-4">No results found.</div>
                            )}
                            {state === "loaded" && Object.keys(results).length > 0 && (
                                <div className="pt-8">
                                    {Object.keys(results).map((group, i) => (
                                        <div className="mb-4" key={i}>
                                            <h3 className="base-paragraph text-xs text-muted-foreground mb-1">{group}</h3>
                                            <div className="space-y-2">
                                                {results[group].map((item, iv) => (
                                                    <button
                                                        key={iv}
                                                        onClick={() => window.location.href = item.url}
                                                        className="w-full rounded-lg py-4 text-left border-b border-border border-spacing-2"
                                                    >
                                                        {/* Title and Icon */}
                                                        <div className="flex items-center space-x-3">
                                                            <IoDocumentOutline />
                                                            <span
                                                                className="base-paragraph text-medium text-foreground uppercase text-sm"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: item.name.replace(
                                                                        new RegExp(input, "gi"),
                                                                        (match) => `<mark>${match}</mark>`
                                                                    ),
                                                                }}
                                                            ></span>
                                                        </div>

                                                        {/* URL */}
                                                        <div className="XsBase-paragraph text-muted-foreground mt-2">
                                                            {item.url}
                                                        </div>

                                                        {/* Context */}
                                                        {item.context && (
                                                            <div
                                                                className="text-sm text-gray-600 mt-3"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: item.context.replace(
                                                                        new RegExp(input, "gi"),
                                                                        (match) => `<mark>${match}</mark>`
                                                                    ),
                                                                }}
                                                            ></div>
                                                        )}
                                                    </button>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default SearchModal;
